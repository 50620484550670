// Generated color lists
$generated: ();

$white: #fff;
$black: #16130f;
$primary: #55a0d8;
$secondary: #958d77;
$success: #3c763d;
$fail: #b52a1d;
$accent: #b53b38;
$grey: #263238;
$fg: #3e3c3e;
$bg: #ece9de;

$colors-list: (
    primary: (
        $white,
        $primary,
        #1e3060,
        0.8,
    ),
    secondary: (
        $white,
        $secondary,
        #133415,
        0.2,
    ),
    success: (
        $white,
        $success,
        #0d2c10,
        1.5,
    ),
    fail: (
        $white,
        $fail,
        #2c0006,
        1,
    ),
    accent: (
        $white,
        $accent,
        #321900,
        1,
    ),
    'grey': (
        $white,
        $grey,
        $black,
        0,
    ),
    fg: (
        $white,
        $fg,
        $black,
        0.1,
    ),
    bg: (
        $white,
        $bg,
        #5b584e,
        1,
    ),
);

@each $name, $color in $colors-list {
    // Current color map
    $current: ();

    // Generates the colors transformations (shades)
    @for $i from 1 through 49 {
        $current: append($current, mix(nth($color, 3), nth($color, 2), (100 - ($i * 2))));
    }

    // Adds the current color
    $current: append($current, nth($color, 2));

    @for $i from 51 through 99 {
        //$current: append($current, mix(nth($color, 1), nth($color, 2), ($i * 2) - 100));

        //$newColor: mix(nth($color, 1), saturate(nth($color, 2), ($i * 2) - 100), ($i * 2) - 100);
        //$current: append($current, $newColor);

        $setFac: nth($color, 4);
        $newColor: mix(nth($color, 1), nth($color, 2), ($i * 2) - 100);
        $current: append($current, saturate($newColor, (($i - 50) * $setFac)));
    }

    // If the map has not been created
    @if map-has-key($generated, $name) == false {
        $generated: map-merge(
            $generated,
            (
                $name: $current,
            )
        );
    }
}

// Create a function to get the desired color
@function get-color($color, $index: 50) {
    // Default to base color

    // Get the desired color map
    $list: map-get($generated, $color);

    @if length($list) < 2 {
        @debug '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!';
        @error "Property #{$color} must have length > 1";
        @debug $color;
        @debug $index;
        @debug length($list);
        @debug $list;
    }

    // Return the color index
    @return nth($list, $index);
}

// TODO: remove this in prod
@each $name, $color in $colors-list {
    @for $i from 1 through 99 {
        @if $i % 5 == 0 {
            $___colorClassPrefix: 'color';
            .#{$___colorClassPrefix}-#{$name}-#{$i} {
                background-color: get-color($name, $i);
            }
        }
    }
}
