@import "color";
@import "spacing";
@import "breakpoint";
@import "hover";

$iconWrapperWidth: 50px;
$borderRadius: 6px;
$buttonHeight: 46px;

.btn {
    display: inline-flex;
    flex: 0 0 auto;
    width: min-content;
    height: $buttonHeight;
    white-space: nowrap;
    border-radius: $borderRadius;
    background-color: get-color(fg, 40);
    color: get-color(bg, 50);
    fill: currentColor;
    padding: 0 26px;
    position: relative;
    font-size: 0.8em;
    text-transform: uppercase;
    border: none;
    margin: 0 1px;
    user-select: none;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: box-shadow 300ms;
    cursor: pointer;

    @include bp($brkTablet) {
        height: $buttonHeight + 8px;
    }

    @include hover {
        box-shadow: inset 0 0 60px 0 rgba(0, 0, 0, 0.3);
    }

    @include bp($brkPhoneS) {
        font-size: 0.75em;
        padding: 0 18px;
    }
}

.btnHasIcon {
    padding-left: $iconWrapperWidth + $spacing-s;

    @include bp($brkPhoneS) {
        padding-left: $iconWrapperWidth + $spacing-xs;
    }

    svg {
        width: 20px;
        position: absolute;
        top: 50%;
        left: $iconWrapperWidth / 2;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: $iconWrapperWidth;
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.btnBlock {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
}

.btnPrimary {
    background-color: get-color(primary, 40);
}

.btnSecondary {
    background-color: get-color(secondary, 50);
}

.btnBorder {
    background-color: transparent;
    border: 1px solid get-color(fg, 40);
    color: get-color(fg, 40);
}

.btnDisabled {
    filter: grayscale(40%) brightness(120%);
    cursor: not-allowed;
}

.btnSmall {
    zoom: 60%;
}
