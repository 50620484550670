@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/vars";

$titlePaddingTop: 8px;

.wrapper {
    position: relative;
}

.wrapperIsExpanded {

}

.title {
    display: flex;
    align-items: center;
    color: $grey500;
    font-weight: normal;
    cursor: pointer;
    padding: $titlePaddingTop 10px 3px;
    border-bottom: 1px solid $grey100;

    &:hover:not(.isExpanded) {
        @include desktop {
            background-color: $grey100;
            border-top: 1px solid $blueGrey100;
            border-bottom: 1px solid $blueGrey200;
            padding-top: $titlePaddingTop - 1;
        }
    }

    &.isExpanded {
        background-color: $grey100;
    }

    @include phone {
        font-size: 0.9em;
    }

    svg {
        width: 16px;
        margin-right: 5px;
        fill: $grey400;
    }
}

.plusMinusIcon {
    position: relative;
    top: 1px;
    color: $blueGrey400;
    margin-right: 10px;
}

.titleWithNoPrograms {
    font-weight: normal;
    color: $grey300;
}

.noProgramsInfo {
    padding: 5px 0 15px 25px;
}

ul.programsWrapper {
    padding: 0 0 10px 0;
    margin: 0;
    position: relative;
    border-top: 1px solid $blueGrey200;

}
