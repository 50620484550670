$micro: "(max-width: 350px)";
$phonep: "(max-width: 460px)";
$phone: "(max-width: 767px)";
$phablet: "(min-width: 400px) and (max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$notDesktop: "(max-width: 1023px)";
$desktop: "(min-width: 1024px)";

$w1120: "(min-width: 1120px)";
$w1280: "(min-width: 1280px)";
$w1650: "(min-width: 1650px)";

@mixin micro { @media #{$micro}   { @content; } }
@mixin phonep { @media #{$phonep}   { @content; } }
@mixin phone { @media #{$phone}   { @content; } }
@mixin phablet { @media #{$phablet}  { @content; } }
@mixin tablet { @media #{$tablet}  { @content; } }
@mixin notDesktop { @media #{$notDesktop} { @content; } }
@mixin desktop { @media #{$desktop} { @content; } }
@mixin w1120 { @media #{$w1120} { @content; } }
@mixin w1280 { @media #{$w1280} { @content; } }
@mixin w1650 { @media #{$w1650} { @content; } }

@mixin min-width($width) { @media (min-width: #{$width}px) { @content; } }
@mixin max-width($width) { @media (max-width: #{$width}px) { @content; } }
@mixin min-max-width($min, $max) { @media (min-width: #{$min}px) and (max-width: #{$max}px) { @content; } }

@mixin box-shadow($blur: 14px, $opacity: 0.25) {
    box-shadow: 0 0 $blur 1px rgba(0,0,0,$opacity);
}

@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

    $at1x_path: "#{$path}.#{$ext}";
    $at2x_path: "#{$path}-large.#{$ext}";

    background-image: url("#{$at1x_path}");
    background-size: $w $h;
    background-position: $pos;
    background-repeat: $repeat;

    @media all and (-webkit-min-device-pixel-ratio : 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
        background-image: url("#{$at2x_path}");
    }
}
