@import "app-shared/scss/color";

$blue50: #ddf3fe;
$blue100: #96e6fa;
$blue200: #4bcced;
$blue300: #2196F3;
//$blue400: #0b9cd4;
$blue400: #0ba4d8;
$blue450: #0b8dbe;
$blue500: #0073b9;
$blue600: #1c6785;

$blueGrey100: #eef6fd;
$blueGrey200: #cddbdf;
$blueGrey300: #8eb8ce;
$blueGrey400: #678ba1;
$blueGrey500: #4b6675;
$blueGrey600: #3c4754;

$grey50: #fdfdfd;
$grey100: #f1f3f5;
$grey200: #d3d3d3;
$grey300: #9c9c9c;
$grey400: #7d7d7d;
$grey500: #575757;
$grey600: #2f2f2f;

$success100: #94ce95;
$success500: #498F4C;

$fail50: #f4cbcf;
$fail100: palevioletred;
$fail200: #db3635;
$fail300: #b5000a;
$fail400: #92000a;
$fail500: #6e000a;

$accent50: #fffae7;
$accent100: #e8dfbe;
$accent200: #fdc67f;
$accent300: #dfaa7a;
$accent600: #855100;

$orange400: #ffa726;
$orange500: #ff9800;
