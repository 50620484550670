@import "../../scss/button";
@import "../../scss/colors";

.wrapper {
    padding: 10px;
}

.title {
    font-size: 1.65em;
    margin: 0 0 10px;
}

.introText {
    position: relative;
    height: 100px;
    overflow: hidden;

    &:not(.expanded):after {
        content: ' ';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 50px;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+0,1+61,0.91+61,1+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,0.91) 61%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,0.91) 61%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,0.91) 61%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    &.expanded {
        height: auto;
        overflow: visible;
    }
}

.readMoreLess {
    font-size: 0.8em;
    text-align: right;
    margin: 5px 30px 20px;
    color: $blue400;
    text-transform: uppercase;
}

.startBtn {
    @extend .btn;
    @extend .btnLarge;
    margin: 20px 0;
    display: block;
}
