@import "../../../scss/vars";
@import "../../../scss/mixins";
@import "../../../scss/colors";

.wrapper {
    box-sizing: border-box;
    //border: 1px solid $blue500;
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    width: $gradeLevelWidth;
    height: $gradeInputHeight;
    cursor: pointer;
    position: relative;
    left: -1px;
    overflow: hidden;

    @include phone {
        width: $gradeLevelWidthPhone;
        height: $gradeInputHeightPhone;
    }

    @include phablet {
        width: $gradeLevelWidthPhablet;
        height: $gradeInputHeightPhablet;
    }

    &:hover {
        overflow: visible;
        z-index: 2000;
    }
}

.innerWrapper {
    position: relative;
    top: -4px;
    width: 100%;
}

.dropDown {
    //border: 1px solid #ccc;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.6);
    background-color: #fff;
    margin: 0;
    padding: 4px 0;
    border-radius: 4px;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: top 0.2s ease-in;
}

.option {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.75em;
    //font-weight: bold;
    width: 100%;
    height: $gradeInputHeight;

    @include phone {
        width: $gradeLevelWidthPhone;
        height: $gradeInputHeightPhone;
    }

    @include phablet {
        width: $gradeLevelWidthPhablet;
        height: $gradeInputHeightPhablet;
    }

    &:hover {
        background-color: #c5e0f8;
    }

    &.optionActive {
        color: #fff;
        background-color: $blue400;
    }
}

.touchWrapper {
    //border-top-right-radius: 3px;
    //border-bottom-right-radius: 3px;
    background-color: $blue400;
    //background-color: $grey500;
    width: $gradeLevelWidth;
    height: $gradeInputHeight;
    position: relative;
    left: -1px;
    overflow: hidden;
    color: #fff;
    font-size: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.5;
    }

    @include phone {
        width: $gradeLevelWidthPhone;
        height: $gradeInputHeightPhone;
    }

    @include phablet {
        width: $gradeLevelWidthPhablet;
        height: $gradeInputHeightPhablet;
    }
}
