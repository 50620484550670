$totalWidth: 695px;
$leftSidebarWidth: 260px;

$topToolbarTopBlockHeight: 38px;
$topToolbarSubmenuBlockHeight: 40px;

$gradeInputHeight: 23px;
$gradeInputWidth: 30px;
$gradeLevelWidth: 20px;

$gradeInputHeightPhone: 22px;
$gradeInputWidthPhone: 40px;
$gradeLevelWidthPhone: $gradeInputHeightPhone;

$gradeInputHeightPhablet: 26px;
$gradeInputWidthPhablet: 45px;
$gradeLevelWidthPhablet: $gradeInputHeightPhablet;

$zIndexBranchTreeLine: 10 * 1000;
$zIndexResultBoxBackdrop: 50 * 1000;
$zIndexTopToolbar: 30 * 1000;
$zIndexSchoolFilter: 60 * 1000;
$zIndexSchoolFilterMobile: 20 * 1000;
