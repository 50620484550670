@import "../../scss/vars";
@import "../../scss/colors";
@import "../../scss/mixins";

//:global {
//    input[type=number]::-webkit-inner-spin-button,
//    input[type=number]::-webkit-outer-spin-button {
//        -webkit-appearance: none;
//        margin: 0;
//    }
//}

.wrapper {
    display: flex;
    flex: 1 1 100%;
    //background-color: $blueGrey100;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 4px 0 10px;
    min-height: 40px;

    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    &.isUsed {
        background-color: $blueGrey200;
    }
}

.title {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 4px 8px 4px 0;
    font-size: 15px;
    overflow: hidden;

    .icon {
        color: $blueGrey200;
        margin-right: 8px;
        flex: 0 0 18px;
    }

    @include phablet {
        font-size: 1em;
        padding: 10px 8px;
    }
}

.inputBoxCell {
    position: relative;
    display: flex;
    flex: 0 0 55px;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    align-content: space-around;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-left: 6px;

    @include phone {
        flex: 0 0 75px;
    }

    @include phablet {
        flex: 0 0 95px;
    }

    input[type=phone], input[type=number] {
        //border: 1px solid $grey200;
        //border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        // border: none;
        border: 1px solid $grey200;
        background-color: #fff;

        box-sizing: border-box;
        width: $gradeInputWidth;
        height: $gradeInputHeight;
        text-align: center;
        -webkit-appearance: none;

        @include phone {
            font-size: 16px;
            padding: 0;
            width: $gradeInputWidthPhone;
            height: $gradeInputHeightPhone;
        }

        @include phablet {
            width: $gradeInputWidthPhablet;
            height: $gradeInputHeightPhablet;
        }

        &.invalidInput {
            background-color: $fail50;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
            border-bottom-color: $grey500;
        }
    }

    select {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 14px;

        @include phone {
            font-size: initial;
        }
    }
}

.exam {
    @extend .inputBoxCell;
}

.annual {
    @extend .inputBoxCell;
}

.test {
    @extend .inputBoxCell;
    flex: 1 1 120px;

    @include phone {
        flex: 0 0 156px;
    }

    @include phablet {
        flex: 0 0 196px;
    }

    .dropDownVisibleValue {
        display: flex;
        flex: 0 0 90%;
        align-items: center;
        justify-content: center;
        font-size: 0.85em;
        border: none;
        // border-bottom: 1px solid $grey300;
        //border-radius: 4px;
        background-color: transparent;
        padding: 4px 6px;
        text-transform: capitalize;

        svg {
            height: 14px;
        }
    }
}

.inputWrapper {
    display: flex;
    width: 15px;
}
