@import "../../scss/mixins";

.wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    font-size: 0.9em;
    width: 100%;
    overflow: hidden;

    @include notDesktop {
        font-size: 0.6em;
    }

    @include phone {
        width: 93%;
        margin: auto;
    }

    @include phablet {
        width: 100%;
        font-size: 0.7em;
    }
}

.subjectWrapper {
    flex: 1 1 auto;
    min-width: 0;
}

.subjectName {
    font-size: 1.1em;
    padding-bottom: 2px;

    @include phone {
        padding-left: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.subjectCalcutations {
    font-weight: bold;
}