@import "../../scss/colors";
@import "../../scss/mixins";

.wrapper {
    position: relative;
    padding: 20px 0 0;
    margin: 0 0 10px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    @include phone {
        margin-left: auto;
        margin-right: auto;
        width: 93%;
    }

    @include phablet {
        width: 98%;
    }
}

.scaleWrapper {
    position: relative;
    height: 10px;
}

.scale {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: #94ce95;
}

.failZone {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $fail100;
    box-sizing: border-box;
    text-align: right;
    padding: 1px 15px 0 0;
    font-size: 0.8em;
    color: #fff;
}

.triangle {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
}

.gpaBaloon {
    position: absolute;
    top: -20px;
    border-radius: 5px;
    background-color: $fail100;
    color: #fff;
    padding: 1px 5px 2px;
    font-size: 0.8em;
    transform: translate(-50%, 0);

    &.failed {
        background-color: $fail400;
    }

    &.failed:after {
        @extend .triangle;
        border-color: $fail400 transparent transparent transparent;
    }

    &.succeeded {
        background-color: $success500;
    }

    &.succeeded:after {
        @extend .triangle;
        border-color: $success500 transparent transparent transparent;

    }
}

.gpaValue {
    display: block;
}
