@import "./colors";
@import "./mixins";

.btn {
    font-size: 0.9em;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    //background-color: $blue300;
    background-color: get-color(primary);
    color: #fff !important;
    padding: 6px 20px 4px;

    @include notDesktop() {
        padding: 6px 20px;
    }

    &:hover {
        background-color: $blue400;
    }
}

.btnWhite {
    @extend .btn;
    background-color: #fff;
    color: $blue300 !important;
    border: 1px solid $blue300;

    &:hover {
        background-color: $blue50;
    }
}

.btnSmall {
    font-size: 0.9em;
    padding: 3px 10px;
}

.btnLarge {
    font-size: 1.0em;
    padding: 5px 30px;
}

.btnOnInput {
    padding: 3px 4px;
    margin-left: -32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
