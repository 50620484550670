@import "../../scss/colors";
@import "../../scss/mixins";

.wrapper {
    font-size: 0.9em;
    line-height: 1.4;
    margin: 10px 10px 5px;
    text-align: left;

    @include notDesktop {
        font-size: 0.6em;
    }

    @include phablet {
        font-size: 0.8em;
    }
}

.icon {
    float: left;
    margin: 0 15px 0 0;

    @include micro {
        display: none;
    }

    @include phone {
        margin-right: 10px;
        font-size: 30px;
    }
}

.mainMessage {
    font-weight: bold;
    margin-bottom: 6px;
}

.resultMessage {
    font-weight: normal;

    //@include phone {
    //    display: none;
    //}
    //
    //@include phablet {
    //    display: block;
    //}
}

.resultIcon {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;
}

.success {
    color: $success500;

}

.fail {
    color: $fail400;

}
