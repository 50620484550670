@import "../../scss/colors";
@import "../../scss/vars";
@import "../../scss/mixins";

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
    //background-color: $accent50;
    background: $orange400 linear-gradient(180deg, $orange400, $orange500) repeat-x;
    //border: 1px solid $accent200;
    color: #fff;
    z-index: $zIndexResultBoxBackdrop - 5;
    transition: all 0.1s;
    @include box-shadow();

    @include notDesktop {
        position: fixed;
    }

    &.expanded {
        border-radius: 8px;
        width: auto;
        height: auto;
        padding: 15px 25px 15px 15px;
        right: 10px;
        bottom: 10px;
        left: 10px;
    }

    h2, h3, h4 {
        margin: 5px 0 10px;
    }

    ul, li {
        margin: 0;
    }
}

.closeIcon {
    position: absolute;
    display: flex;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: $orange500;
    color: #fff;
    top: -4px;
    right: -4px;
    line-height: 0;
}

.exclamationIcon {
    font-size: 30px;
    color: #fff;
    transform: translate(0, -2px);
}
