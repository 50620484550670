@import "../../scss/vars";
@import "../../scss/colors";
@import "../../scss/mixins";

.wrapper {
    font-size: 15px;

    &.hasErrors {
        padding-bottom: 30vh;
    }

    @include phone {
        margin-top: 15px;
    }

    @include phablet {
        font-size: 1.2em;
    }
    @include desktop {
        // width: $leftSidebarWidth;
    }
}

.stickyMode {
    width: $leftSidebarWidth;
    position: fixed;
    top: $topToolbarTopBlockHeight + 10px;
    bottom: 0;
    padding-bottom: 350px;
    box-sizing: border-box;
    overflow: auto;

    @include phone {
        top: $topToolbarTopBlockHeight + $topToolbarSubmenuBlockHeight + 10px;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }
}

.ul {
    padding: 0;
    margin: 0;
    border-bottom: 2px solid $blueGrey200;
}

.header {
    font-size: 0.9em;
    //font-weight: bold;
}
