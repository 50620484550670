@import '../../scss/vars';
@import '../../scss/button';
@import '../../scss/mixins';
@import '../../scss/colors';

.wrapper {
    display: flex;
    flex-direction: column;
    top: -20px;
    z-index: $zIndexTopToolbar;
    box-sizing: border-box;
    position: static;
    background-color: #fff;

    @include desktop {
        border-bottom: 1px solid $grey200;
        width: $totalWidth;
    }

    @include phone {
        @include box-shadow(4px, 0.4);
        //background-color: $blue300;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        //padding: 0 15px;
    }
}

.stickyTopToolbar {
    position: fixed;
    transition: top 200ms cubic-bezier(0.445, 0.385, 0.53, 1.65);
    top: 0;
}

.topBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    height: $topToolbarTopBlockHeight;

    @include desktop() {
        height: $topToolbarTopBlockHeight * 1.1;
    }

    @include notDesktop() {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.fillSpace {
    flex: 1 1 auto;
}

.submenuBlock {
    display: flex;
    background-color: #fff;
    align-items: stretch;
    justify-content: space-around;
    height: $topToolbarSubmenuBlockHeight;
    box-sizing: border-box;

    a {
        display: flex;
        flex: 0 0 33.33%;
        align-items: flex-end;
        justify-content: center;
        color: $grey400 !important;
        font-size: 0.75em;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        box-sizing: border-box;
        text-decoration: none;
        padding: 0 4px 4px;

        &.activeSubmenu {
            border-bottom: 2px solid $blue300;
            padding-bottom: 2px;
        }

        @include phablet {
            font-size: 0.8em;
        }
    }
}

.homeIcon {
    @extend .btn;
    display: flex;
    padding: 6px 8px 5px;

    svg {
        color: #fff;
        width: 32px;
        height: 20px;
    }
}

.heading {
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 4px;
    color: $grey500;
}

.magnifyIcon {
    color: $blue300;
}

.schoolFilterButton {
    @extend .btn;
    // text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-left: 15px;

    svg {
        height: 15px;
        width: 15px;
    }
}

.notificationToInputGrades {
    position: relative;
    display: flex;
    align-items: center;
    //background-color: $accent50;
    background: $orange400 linear-gradient(180deg, $orange400, $orange500) repeat-x;
    color: #fff;
    line-height: 1.3;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 0.8em;
    padding: 10px 10px 10px 50px;
    margin: -6px 10px -8px 10px;
    @include box-shadow();

    &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translate(0, -50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 10px 20px 0;
        border-color: transparent $orange500 transparent transparent;
    }

    @include micro {
        font-size: 0.7em;
        padding: 10px 6px 10px 26px;
        margin-right: 5px;
    }

    .exclamationIcon {
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translate(0, -45%);
        font-size: 2.5em;
        color: #fff;
        opacity: 0.4;
    }
}
