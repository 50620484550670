@import "../../scss/colors";
@import "../../scss/button";
@import "../../scss/mixins";

.resultsWrapper {

}

h3.scienceFieldTitle {
	font-weight: normal;
	color: $grey500;
	//border-bottom: 1px solid $grey200;
	padding: 5px 0 2px 10px;
	margin: 20px 0 7px;

	@include notDesktop {
		padding-left: 15px !important;
	}
}

.noResultsWrapper {
	background-color: #ffe2c4;
	padding: 20px;
}

.clearSchoolFilterBtn {
	@extend .btn;
	@extend .btnSmall;
	text-transform: uppercase;
	line-height: 1.8;
	display: inline-block;
	margin: 4px 0;
}

.infoBlock {
	background-color: $accent50;
	border: 1px solid $accent200;
	border-radius: 6px;
	padding: 10px 20px;
	margin-bottom: 20px;
	line-height: 1.4em;

	@include notDesktop {
		margin: 10px;
	}

	@include phone {
		font-size: 0.8em;
		line-height: 150%;
	}

	> div {
		margin: 10px 0;
	}
}
