@import "../../scss/colors";
@import "../../scss/vars";
@import "../../scss/mixins";

.wrapper {
    position: relative;
    padding-left: 10px;
    border-bottom: 1px solid $grey100;
    list-style: none;

    &.isActive {
        background-color: $blueGrey100 !important;
    }

    &:nth-child(even) {
        //background: lighten($grey100, 3);
    }

    &:before {
        content: ' ';
        position: absolute;
        display: block;
        border-left: 1px solid $blueGrey300;
        left: 15px;
        top: 0;
        bottom: -2px;
        z-index: $zIndexBranchTreeLine;
    }

    &:after {
        content: ' ';
        position: absolute;
        display: block;
        border-top: 1px solid $blueGrey300;
        left: 15px;
        top: 50%;
        width: 7px;
    }

    &.lastItem {
        &:before {
            bottom: 50%;
        }
    }
}

.link {
    display: flex;
    color: #000;
    text-decoration: none;
    padding: 10px 10px 10px 15px;
    justify-content: center;

    &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 22px;
        transform: translate(0, -50%);
        background-color: $blueGrey300;
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
}

.program {
    font-size: 0.97em;
    display: flex;
    flex: 0 0 58%;
    align-items: center;
    padding-left: 10px;
    color: $grey600;

    @include phone {
        font-size: 0.85em;
    }
}

.school {
    display: flex;
    flex: 1 1 auto;
    color: $blueGrey400;
    font-size: 0.9em;
    padding-left: 15px;

    @include phone {
        font-size: 0.75em;
    }

    @include phablet {

    }
}
