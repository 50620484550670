.ad {
    border: 1px solid #ccc;
    height: 300px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    background-color: #f1f1f1;
    color: #e3e3e3;
}
