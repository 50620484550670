@import 'color';
@import 'spacing';

.note {
    background-color: get-color(primary, 90);
    color: get-color(primary, 30);
    border: 1px solid get-color(primary, 80);
    font-size: 0.9em;
    padding: $spacing-s $spacing-xs;
    text-align: center;
    line-height: 1.6;

    &.noteSuccess {
        background-color: get-color(success, 94);
        color: get-color(success, 58);
        border-color: get-color(success, 85);
    }

    &.noteError {
        background-color: get-color(fail, 95);
        color: get-color(fail);
        border-color: get-color(fail, 80);
    }
}
