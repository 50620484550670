@import "../../scss/colors";
@import "../../scss/button";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    height: 15px;
    //border: 1px solid $grey200;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid $grey200;
    //border-radius: 10px;
    padding: 4px 6px;

    &:focus {
        outline: none;
        border-color: $grey500;
    }
}

.inputWithButton {
    height: 34px;
    padding: 0 50px 0 10px;
}

.searchButton {
    padding: 3px 4px;
    margin-left: -32px;
    color: $grey400;
}
