//@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display&display=swap');

$__INTERNAL_USE_ONLY_FONT_HEADING__: 'Playfair Display', serif;
$__INTERNAL_USE_ONLY_FONT_BODY__: 'Open Sans', sans-serif;

$__INTERNAL_USE_ONLY_FAMILY: (
        heading: $__INTERNAL_USE_ONLY_FONT_HEADING__,
        body: $__INTERNAL_USE_ONLY_FONT_BODY__
);

@mixin font-family($family) {
    @if map-has-key($__INTERNAL_USE_ONLY_FAMILY, $family) {
        font-family: map-get($__INTERNAL_USE_ONLY_FAMILY, $family);
    }
}
