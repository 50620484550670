@import "../../scss/global.scss";
@import "../../scss/button.scss";

.wrapper {
    position: relative;
    min-height: 150vh;
    box-sizing: border-box;

    @include notDesktop {
        min-height: 100%;
    }
}

.loading {
    text-align: center;
    padding-top: 30%;
    color: $grey300;
}

.desktopIntroWrapper {
    margin-bottom: 30px;
    font-size: 0.9em;
}

.verticalSplit {
    display: flex;
    flex: 1 0 100%;

    @include desktop {
        margin-top: 10px;
        width: $totalWidth;
    }

    @include notDesktop {
        //margin-top: 38px;
    }

    @include phablet {
        //margin-top: 64px;
    }
}

.mobileBlock {
    @include notDesktop {
        width: 100%;
        flex: 0 0 100% !important;
        //margin: 0;
        padding: 0;
        margin: 0 0 100px 0;
    }
}

.leftBlock {
    flex: 0 0 $leftSidebarWidth;
    padding: 0;
    background-color: #fdfdfd;

    @extend .mobileBlock;
}

.rightBlock {
    flex: 1 1 auto;

    @include notDesktop {
        padding-top: 10px;
    }

    @include desktop {
        padding: 0 0 0 20px;
        margin-bottom: 200px;
    }

    @extend .mobileBlock;
}

.topToolbarHolder {
    height: $topToolbarTopBlockHeight;

    @include phone {
        height: $topToolbarTopBlockHeight + $topToolbarSubmenuBlockHeight;
    }
}
