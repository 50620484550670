@import '../../scss/vars';
@import '../../scss/colors';
@import '../../scss/mixins';

$border-radius: 6px;

.backdrop {
    position: fixed;
    //background-color: rgba(255, 255, 255, 0.85);
    background-color: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //pointer-events: none;
    z-index: $zIndexResultBoxBackdrop;

    @include phablet {
        top: $topToolbarTopBlockHeight + $topToolbarSubmenuBlockHeight + 1;
    }
}

.wrapper {
    display: flex;
    position: fixed;

    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: #fff;

    padding: 30px 10px 20px;
    border: 1px solid $grey300;
    border-radius: $border-radius;
    //min-height: 140px;
    line-height: 1;
    z-index: $zIndexResultBoxBackdrop + 1;
    pointer-events: all;
    cursor: grab;
    @include box-shadow();

    @include desktop {
        width: $totalWidth * 0.9;
        bottom: 40px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    @include notDesktop {
        right: 6px;
        bottom: 6px;
        left: 6px;
        padding: 25px 10px 5px;
        min-height: auto;
    }

    @include phone {
        right: -4px;
        bottom: 0;
        left: -4px;
        padding-bottom: 15px;
    }

    @include phablet {
        right: 6px;
        bottom: 6px;
        left: 6px;
    }

    @include phablet {
        padding: 25px 10px 15px;
    }
}

.innerWrapper {
    overflow-y: auto;
    margin-bottom: 10px;
    width: 100%;
}

.error {
    min-height: 0;
    font-size: 1.1em;
    line-height: 1.5em;
    border: none;
    color: #fff;
    padding-top: 10px;
    background-color: $fail200;
    cursor: initial;

    @include phone {
        font-size: 0.9em;
    }
}

.closeIcon {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: $zIndexResultBoxBackdrop + 2;
    text-decoration: none;
    fill: $grey500;
    cursor: pointer;

    .error & {
        // fill: $fail200;
        fill: #fff;
    }

    @include phone {
        background-color: #fff;
        border-radius: 50px;
        border: 1px solid $grey300;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: 5px;

        .error & {
            border-color: $fail200;
            fill: $fail200;
        }
    }
}

.programName {
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 30px 6px 10px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: $grey500;
    background-color: $grey100;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    user-select: none;
}

.title {
    font-weight: bold;
    font-size: 1.4em;
    text-align: center;
    margin: 10px 0;

    @include notDesktop {
        font-size: 1em;
    }

    @include phablet {
        font-size: 1.3em;
    }
}

.note {
    font-size: 0.8em;
    color: $accent600;
    background-color: $accent50;
    border-radius: 6px;
    padding: 8px 15px;
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;

    @include phone {
        font-size: 0.7em;
    }
}

.adHolder {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
    pointer-events: all;

    @include phone {
        padding-top: 5px;
    }

    @include phablet {
        padding-top: 15px;
    }
}

.toc {
    position: absolute;
    right: 0;
    bottom: 6px;
    left: 0;
    text-align: center;
    color: $fail500;
    font-size: 0.7em;
    //text-transform: uppercase;

    @include phone {
        text-transform: none;
    }

    @include micro {
        font-size: 0.6em;
    }
}

.skeletonBlock {
    background-color: $blueGrey100;
    margin: 4px 0;
}
