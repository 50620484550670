@import 'ads';
@import 'buttons';
@import 'color';
@import 'fonts';
@import 'spacing';
@import 'note';

html, body, #__next {
    height: 100%;
}

body {
    @include font-family(body);
    margin: 0;
    padding: 0;
    line-height: 1.5;
    min-height: 100vh;
}

* {
    box-sizing: border-box;
}

h1 {
    -webkit-font-smoothing: antialiased;
    @include font-family(heading);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-style: normal;
}

a {
    color: get-color(accent);
}

pre {
    max-width: 100%;
    box-sizing: border-box;
    overflow: auto;
}

hr {
    border: none;
    border-top: 1px solid get-color(bg, 40);
    background-color: transparent;
    height: 1px;
    margin-bottom: $spacing-s;
}

.vc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tac {
    text-align: center;
}

:focus {
    border-color: transparent;
}

svg {
    fill: currentColor
}
