@import "../../scss/colors";
@import "../../scss/mixins";

.wrapper {
    flex-direction: column;
    padding: 15px 50px;

    ul {
        margin: 0;
        padding: 0;
    }
}

.title {
    text-transform: uppercase;
    font-size: 1.4em;
    margin-bottom: 15px;

    @include notDesktop {
        margin: 0;
    }
}

.selectActionsWrapper {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
    display: block;
    margin: 15px 0 20px;

    .btn {
        font-weight: normal;
        font-size: 90%;
        border-radius: 4px;
        background-color: $blue300;
        color: #fff;
        padding: 2px 4px;
        cursor: pointer;

        &:hover {
            background-color: $blue500;
        }
    }
}

.listItem {
    position: relative;
    list-style: none;
    padding: 0 0 0 25px;
    margin-bottom: 8px;

    @include notDesktop {
        font-size: 0.9em;
        padding-top: 6px;
    }

    input[type=checkbox] {
        position: absolute;
        left: 0;
    }
}
