@import "../../scss/colors";
@import "../../scss/button";

.blockWrapper {
    border-radius: 6px;
    background-color: $grey100;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 10px;

    h2 {
        font-size: 1.2em;
        margin: 10px 0 10px 0;
        text-transform: uppercase;
        color: $grey500;
    }

    .btn {
        @extend .btn;
        @extend .btnLarge;
        text-transform: uppercase;
    }
}

.btnWrapper {
    padding: 10px;
}

.searchField {
    input[type=text] {
        font-size: 0.9em;
    }
}
