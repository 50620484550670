$spacing-xs: 9px;
$spacing-s: 18px;
$spacing-m: 27px;
$spacing-l: 36px;
$spacing-xl: 45px;
$spacing-xxl: 54px;
$spacing-xxxl: 72px;

.mts {
    margin-top: $spacing-s;
}

.mbs {
    margin-bottom: $spacing-s;
}
