@import "../../scss/colors";
@import "../../scss/button";
@import "../../scss/mixins";
@import "../../scss/vars";

.wrapper {
    position: fixed;
    background-color: #fff;
    overflow: auto;
    box-sizing: border-box;
    z-index: $zIndexSchoolFilter;

    @include phone {
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }

    @include desktop {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100vh;
        width: 800px;
        @include box-shadow()
    }

    @include notDesktop {
        top: $topToolbarTopBlockHeight + $topToolbarSubmenuBlockHeight;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $zIndexSchoolFilterMobile;
    }
}

.listWrapper {
    display: flex;
    padding: 15px 0 40px;

    @include phone {
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }

    @include notDesktop {
        overflow: auto;
        flex-direction: column;
    }

    > div {
        padding: 10px 10px 10px 20px;

        @include desktop {
            flex: 1 1 50%;
            display: flex;
            padding: 0 10px 10px 20px;
        }

        @include notDesktop {
            padding-top: 15px;
        }
    }
}

.footer {
    background-color: $grey100;
    padding: 10px 30px;
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-end;

    @include notDesktop {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.btn {
    @extend .btn;
}

.btnWhite {
    @extend .btnWhite;
    margin-right: 10px;
}
