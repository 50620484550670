@mixin hover {
    /*
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
     * coarse: The primary input mechanism includes a pointing device of limited accuracy.
     */
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}
